import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import DashboardIcon from '@mui/icons-material/Dashboard';
import StorageIcon from '@mui/icons-material/Storage';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';

export function MainListItems( { page, setPage } ) {

  const handleGotoPage = (page, event) => {
    event.preventDefault();
    setPage(page);
  }

  return (
    <React.Fragment>
      <ListItemButton selected={page==="account" ? true : false} onClick={(event) => handleGotoPage('account', event)} >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItemButton>
      <ListItemButton disabled>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Statistics" />
      </ListItemButton>
      <ListItemButton selected={page==="servers" ? true : false} onClick={(event) => handleGotoPage('servers', event)} >
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Servers" />
      </ListItemButton>
      <ListItemButton selected={(page==="app" || page==="main") ? true : false} onClick={(event) => handleGotoPage('main', event)}>
        <ListItemIcon>
          <VideogameAssetIcon />
        </ListItemIcon>
        <ListItemText primary="Battles" />
      </ListItemButton>
  
    </React.Fragment>
  );

}

export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);

