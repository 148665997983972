import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.floppybattle.NET/">
        FloppyBattle.NET
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login( { setPage, cookies, setDeviceStatus, recordDevice } ) {

    const handleGotoHome = () => {
      setPage('home');
    }

    const handleLogin = ( deviceMacAddr ) => {
        if( deviceMacAddr !== '' ) {
            fetch(`https://api.floppybattle.net/` + deviceMacAddr + `/status`)
            .then((response) => response.json())
            .then((actualData) => {
            if( actualData.device_status.account_id > 0 ) {
                recordDevice( deviceMacAddr );
                setDeviceStatus( actualData.device_status );
            } else {
                recordDevice( '' );
                setOpenloginError(true)
            }
            })
            .catch((err) => {
                console.log(err.message);
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        handleLogin( data.get('deviceMacAddr') );
        event.target.deviceMacAddr.value = '';
    };

    const [loginError, setOpenloginError] = React.useState(false);

    const handleLoginErrors = () => {
        setOpenloginError(!loginError);
    };

    if( cookies.get('macAddr') !== '' ) {
        handleLogin( cookies.get('macAddr') );
    }

  return (
    <ThemeProvider theme={theme}>

      <Dialog open={loginError}>
        <Alert severity="error"
            onClose={handleLoginErrors}
          >
          <AlertTitle>Authentification failed:</AlertTitle>
          Device identifier not found.
          
        </Alert>
      </Dialog>

      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://www.floppybattle.NET/media/login.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 14,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Register your device here
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="deviceMacAddr"
                label="Device identifier"
                name="deviceMacAddr"
                autoComplete=""
                autoFocus
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="inherit"
                sx={{ mt: 0, mb: 2 }}
                onClick={(event) => handleGotoHome()}
              >
                Cancel
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">

                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

      </Grid>

    </ThemeProvider>
  );
}
/*
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel 
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
*/
//Forgot password?
//{"Don't have an account? Sign Up"}
