//import JsonDataDisplay from './MyPractice/GeekTable';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import Home from './Home';
import Login from './dashboard/Login';
import Dashboard from './dashboard/Dashboard';
import { PagesTwoTone } from '@mui/icons-material';

function App() {

  const cookies = new Cookies();

  const [page, setPage] = useState('home');
  const [device, setDevice] = useState(cookies.get('macAddr'));
  const [deviceStatus, setDeviceStatus] = useState([]);

  function recordDevice( macAddr ) {
    setDevice( macAddr );
    cookies.set( 'macAddr', macAddr, { path: '/', maxAge: 10 * 365 * 24 * 60 * 60 });
  }

  //if( !deviceStatus.connected ) return (
  if( page !== 'home' && page !== '' ) {

    if( deviceStatus.account_id > 0 ) {

      return (
        <div className="App">
          <Dashboard page={page} setPage={setPage} cookies={cookies} device={device} deviceStatus={deviceStatus} setDeviceStatus={setDeviceStatus} recordDevice={recordDevice} />
        </div>
      );
    
    } else {

      return (
        <div className="App">
          <Login setPage={setPage} cookies={cookies} setDeviceStatus={setDeviceStatus} recordDevice={recordDevice} />
        </div>
      );

    }

  } else {

    return (
      <div className="App">
        <Home setPage={setPage} />
      </div>
    );

  }

}
 
export default App;

// @mui/material, @mui/icons-material, @emotion/styled, @emotion/react, recharts.
//<JsonDataDisplay/>
//      <h1>Hello Geeks!!!</h1>

