import * as React from 'react';
//import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import Badge from '@mui/material/Badge';
import DnsIcon from '@mui/icons-material/Dns';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Title from './Title';


//function preventDefault(event) {
//  event.preventDefault();
//}

export default function Battles( { deviceStatus, setBattlePopup, battles, device, getServerInfo, changeServer } ) {

  function joinBattle(battle_id, event) {
    event.preventDefault();
    //console.log('join Battle #' + battle_id);
    fetch(`https://api.floppybattle.net/` + device + `/battle/join/` + battle_id)
      .then((response) => response.json())
      .then((actualData) => {
        //console.log(actualData);
        setBattlePopup(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function makeServerButton( server_id ) {

    if( server_id !== deviceStatus.server_id ) {

      var serverName = getServerInfo(server_id).name;

      return (
        <React.Fragment >
          <Button
            variant="contained"
            color={'primary'}
            onClick={(event) => changeServer(server_id, event)}
            endIcon={<DnsIcon />}>
            join {serverName}
          </Button>
        </React.Fragment>
      );

    } else {

      return (
        <React.Fragment >
          <Button
            variant="contained"
            color={'inherit'}
            style={{pointerEvents: "none", opacity: "0.4"}}
            >
           Current
          </Button>
        </React.Fragment>
      );

    }

  }

  function makePlayButton( battle_id, server_id ) {

    if( server_id == deviceStatus.server_id ) {

      return (
        <React.Fragment >
          <Button
            variant="contained"
            color={'secondary'}
            onClick={(event) => joinBattle(battle_id, event)}
            endIcon={<ElectricBoltIcon />}>
            Play
          </Button>
        </React.Fragment>
      );

    } else {

      return (
        <React.Fragment >
          <Button
            variant="contained"
            color={'inherit'}
            style={{pointerEvents: "none", opacity: "0.4"}}
            endIcon={<ElectricBoltIcon />}>
            Play
          </Button>
        </React.Fragment>
      );

    }

  }

  return (

    <React.Fragment>
      <div style={deviceStatus.authentified !== true ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Title>Players ready to battle</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Game</TableCell>
              <TableCell>Server</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {battles.filter(filter => filter.device_id !== deviceStatus.device_id && filter.battle_id !== deviceStatus.linked_battle_id ).map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.location} /{item.country}</TableCell>
                <TableCell>{item.game_name }</TableCell>
                <TableCell>{makeServerButton(item.server_id )}</TableCell>
                <TableCell>{makePlayButton(item.battle_id, item.server_id )}</TableCell>
              </TableRow>
            
          ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

//<Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
//  See more battles
//</Link>