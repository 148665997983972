import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.floppybattle.net" target="_self">
        FloppyBattle.NET
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const gamesList = [
    {
      title: 'BattleChess',
      subheader: '',
      state: '15',
      description: [
        'fully playable',
      ],
      imgSrc: 'media/battlechess.png',
      buttonText: '',
      buttonVariant: '',
    },
    {
      title: 'Populous',
      state: '30',
      description: [
        'fully playable',
      ],
      imgSrc: 'media/populous.png',
      buttonText: '',
      buttonVariant: '',
    },
    {
        title: 'Lotus',
        state: '30',
        description: [
          'beta playable',
        ],
        imgSrc: 'media/lotus2.png',
        buttonText: '',
        buttonVariant: '',
    },
    {
        title: 'Vroom',
        state: '0',
        description: [
          'in developpement',
        ],
        imgSrc: 'media/vroom.png',
        buttonText: '',
        buttonVariant: '',
    },
    {
        title: 'Skidmarks',
        state: '30',
        description: [
          'in developpement',
        ],
        imgSrc: 'media/skidmarks.png',
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'Power Monger',
        state: '30',
        description: [
          'in developpement',
        ],
        imgSrc: 'media/powermonger.png',
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'StuntCarRacer',
        state: '30',
        description: [
          'to do',
        ],
        imgSrc: 'media/stuntcarracer.png',
        buttonText: '',
        buttonVariant: '',
      },
      {
        title: 'Xtreme Racing',
        state: '30',
        description: [
          'to do',
        ],
        imgSrc: 'media/xtremeracing.png',
        buttonText: '',
        buttonVariant: '',
      },
      /*
    {
        title: 'BlitzBombers',
        state: '30',
        description: [
          'in developpement',
        ],
        imgSrc: 'media/blitzbombers.png',
        buttonText: '',
        buttonVariant: '',
      },*/
  ];

const fbtTheme = createTheme({

    typography: {
      
      body1: {
  
      },
      h6: {
        textAlign: "left"
      },   
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            background: "repeating-linear-gradient(transparent, transparent 2px, #350260 10px, transparent 2px), #350268",
            backgroundSize: "auto 100%",
            backgroundPosition: "center center",
            opacity: 1,
          },
        },
      },
    },
  
  });

export default function Home( { setPage } ) {

    const handleGotoApp = (page) => {
        setPage('app');
    }

    return (
    <ThemeProvider theme={fbtTheme}>
      <React.Fragment>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        <AppBar
            position="static"
            elevation={0}
            sx={{ borderBottom: (fbtTheme) => `1px solid ${fbtTheme.palette.divider}` }}
        >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                    <Grid container width={270} paddingTop={1} spacing={2} alignItems={'center'}>
                        <Grid item>
                            <img src="logo512.png" width={45} height={45}  />
                        </Grid>
                        <Grid item>
                            FloppyBattle.NET
                        </Grid>
                    </Grid>
                </Typography>
                <Button href="#" color='secondary' variant="contained" sx={{ my: 1, mx: 1.5 }} onClick={(event) => handleGotoApp()}>
                    Beta-testers
                </Button>
            </Toolbar>
        </AppBar>

        {/* Title unit */}
        <Container disableGutters component="main" sx={{ pt: 4, pb: 5 }}>
            <Typography
            component="h1"
            variant="h2"
            align="center"
            color="primary"
            gutterBottom
            >
            The retro-cross-play network
            </Typography>
            <Typography variant="h5" align="center" color="secondary" component="p">
            ..:: actually in developpement ::..
            </Typography>
            <Container maxWidth="md" component="main">
                <CardMedia
                    sx={{
                        maxWidth: 350,
                        margin: "0 auto",
                        pt: 4,
                    }}
                    component='img'
                    image={"logo512.png"}
                    alt=''
                />
            </Container>
        </Container>
        {/* End title unit */}

        {/* Connect unit */}
        <Container disableGutters component="main" sx={{ pt: 4, pb: 4 }}>
            <Typography variant="h5" align="center" color="primary" component="p">
            Plug in, and play online the null-modem games on your genuine computer!
            </Typography>
            <Container maxWidth="md" component="main">
                <CardMedia
                    sx={{
                        margin: "0 auto",
                        pt: 4
                    }}
                    component='img'
                    image={"media/worldmap.png"}
                    alt=''
                />
            </Container>
        </Container>
        {/* End connect unit */}

        {/* GamesList unit */}
        <Container disableGutters component="main" sx={{ pt: 4, pb: 4 }}>
            <Typography variant="h5" align="center" color="primary" component="p">
            The modern online experience of famous games from your childhood come true:
            </Typography>
        </Container>
        <Container maxWidth="md" component="main">
            <Grid container spacing={4} alignItems="flex-end">
            {gamesList.map((gameInfo) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                item
                key={gameInfo.title}
                xs={12}
                sm={gameInfo.title === ' ' ? 12 : 6}
                md={3}
                >
                <Card>
                    <CardHeader
                    title={gameInfo.title}
                    subheader={gameInfo.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={gameInfo.title === 'Pro' ? <FontAwesomeIcon icon={brands('facebook')} /> : null}
                    subheaderTypographyProps={{
                        align: 'center',
                    }}
                    sx={{
                        backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                    }}
                    />
                    <CardContent>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={gameInfo.imgSrc}
                            alt={gameInfo.imgSrc}
                        />
                    </Box>
                    <ul>
                        {gameInfo.description.map((line) => (
                        <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                        >
                            {line}
                        </Typography>
                        ))}
                    </ul>
                    </CardContent>
                    <CardActions>

                    </CardActions>
                </Card>
                </Grid>
            ))}
            </Grid>
        </Container>
        {/* GamesList unit */}

        {/* Video unit */}
        <Container disableGutters component="main" sx={{ pt: 8, pb: 4 }}>
            <Typography variant="h5" align="center" color="primary" component="p">
            Discover on video how it's run:
            </Typography>
        </Container>
        <Container maxWidth="md" component="main">
            <CardMedia
                component='video'
                image={"media/FloppyBattleTeaser-LotusII.mp4"}
                preload
                controls
            />
        </Container>
        {/* End title unit */}

        {/* Footer */}
        <Container
            maxWidth="md"
            component="footer"
            sx={{
            borderTop: (fbtTheme) => `1px solid ${fbtTheme.palette.divider}`,
            mt: 8,
            py: [3, 1],
            }}
        >
            <Grid container spacing={2} alignItems={'center'} justifyContent="center">
                <Grid item>
                    Follow us:
                </Grid>
                <Grid item>
                    <IconButton href="https://www.facebook.com/FloppyBattle.NET" target="_blank">
                        <FontAwesomeIcon
                            icon={brands('facebook')}
                            size={'1x'}
                            color={'grey'}
                            beat
                        />
                    </IconButton>
                </Grid>
                <Grid item>
                    Join us:
                </Grid>
                <Grid item>
                    <IconButton href="https://discord.gg/mDvQHKyd" target="_blank">
                        <FontAwesomeIcon
                            icon={brands('discord')}
                            size={'1x'}
                            color={'grey'}
                            beat
                        />
                    </IconButton>
                </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
        </Container>
        {/* End footer */}
      </React.Fragment>
    </ThemeProvider>
  );
}