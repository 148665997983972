import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
//import DialogTitle from '@mui/material/DialogTitle';
//import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogActions from '@mui/material/DialogActions';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { arcadeCompany } from "./myicons/fa-arcadecompany";

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import OutletTwoToneIcon from '@mui/icons-material/OutletTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import { MainListItems, secondaryListItems } from './listItems';

//import Devices from './Devices';
import Games from './Games';
import Battles from './Battles';
import Servers from './Servers';
import Account from './Account';

//import { ListItem, touchRippleClasses } from '@mui/material';
import '../App.css';
import { TextSnippetTwoTone } from '@mui/icons-material';
//import { unstable_createCssVarsProvider } from '@mui/system';

library.add(fab, arcadeCompany);


function preventDefault(event) {
  event.preventDefault();
}

function Footer(props) {
  return (
    <Typography variant="body2" color="#777" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.floppyBattle.NET/">
        FloppyBattle.NET
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function DisconnectedWindow(props) {
  const { onClose, open, page, setPage } = props;
  const handleClose = ( event, reason ) => {
    if (reason && reason == "backdropClick") 
        return;
    setPage('account')
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <AppBar position="static">
        <Typography variant="h6" padding={1} component="div" sx={{ flexGrow: 1 }}>
          <Button color={'inherit'} onClick={handleClose} startIcon={<CloseIcon />} />
        </Typography>
      </AppBar>
      <Box
          textAlign='center'
          paddingTop={5}
        >
        <Box fontWeight={600}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={3} textAlign={'right'} fontSize={32}>
              <OutletTwoToneIcon fontSize={'inherit'} />
            </Grid>
            <Grid item xs={9} textAlign={'left'} color={'primary'} fontSize={20}>
              Ooops! &nbsp; Your dongle seems unreachable.
            </Grid>
          </Grid>
          <Box paddingTop={2} paddingBottom={5} fontSize={16}>
            Please check your hardware installation.
          </Box>
        </Box>
      </Box>
      <Box textAlign='center' backgroundColor='#222' color='#666' paddingTop={0} paddingBottom={0} fontSize={13}>
      </Box>
    </Dialog>
  );
}

function BattleInProgressWindow(props) {
  const { onClose, selectedValue, open, quitBattle, deviceStatus, battles, getServerInfo } = props;
  const handleClose = ( event, reason ) => {
    if (reason && reason == "backdropClick") 
        return;
    quitBattle();
    onClose(false);
  };

  var battleStencil = [];
  var aggregateDevicesNames = '';
  var serverName = getServerInfo(deviceStatus.server_id).name;

  if( deviceStatus.players_names ) {
    Object.values( JSON.parse(deviceStatus.players_names) ).forEach((player_name) => {
      aggregateDevicesNames += ( player_name != deviceStatus.name ? ( player_name + ' ' ) : '' );
    });
  }

  if( deviceStatus.is_active && !deviceStatus.is_started ) {
    battleStencil.status = 'init';
    battleStencil.versusIconColor = 'lightgrey';
    battleStencil.versusIconShake = false;
    battleStencil.opponentName = 'Waiting opponent';
    battleStencil.opponentNameColor = 'purple';
    battleStencil.opponentIcon = solid('ghost');
    battleStencil.opponentIconColor = 'purple';
    battleStencil.opponentIconBeatFade = true;
    battleStencil.leaveButtonColor = 'primary';
  } else if( deviceStatus.is_active && deviceStatus.is_started && deviceStatus.is_started ) {
    battleStencil.status = 'linked';
    battleStencil.versusIconColor = 'purple';
    battleStencil.versusIconShake = true;
    battleStencil.opponentName = aggregateDevicesNames;
    battleStencil.opponentNameColor = 'black';
    battleStencil.opponentIcon = solid('gamepad');
    battleStencil.opponentIconColor = 'black';
    battleStencil.opponentIconBeatFade = false;
    battleStencil.leaveButtonColor = 'primary';
  } else {
    battleStencil.status = 'unlinked';
    battleStencil.versusIconColor = 'lightgrey';
    battleStencil.versusIconShake = false;
    battleStencil.opponentName = 'Disconnected';
    battleStencil.opponentNameColor = 'lightgrey';
    battleStencil.opponentIcon = solid('gamepad');
    battleStencil.opponentIconColor = 'lightgrey';
    battleStencil.opponentIconBeatFade = false;
    battleStencil.leaveButtonColor = 'secondary';
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <AppBar position="static">
        <Typography variant="h6" padding={2} component="div" sx={{ flexGrow: 1 }}>
        Battle! 
        </Typography>
      </AppBar>
      <Box
          textAlign='center'
          paddingTop={5}
        >
        <Box fontWeight={600}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={3.5} textAlign={'right'}>
              {deviceStatus.name}
            </Grid>
            <Grid item xs={1.5}>
              <FontAwesomeIcon
                icon={solid('gamepad')}
                size={'3x'}
              />
            </Grid>
            <Grid item xs={2.0}>
              <FontAwesomeIcon
                icon={solid('bolt')}
                size={'5x'}
                color={battleStencil.versusIconColor}
                shake={battleStencil.versusIconShake}
              />
            </Grid>
            <Grid item xs={1.5}>
              <FontAwesomeIcon
                icon={battleStencil.opponentIcon}
                size={'3x'}
                color={battleStencil.opponentIconColor}
                beatFade={battleStencil.opponentIconBeatFade}
              />
            </Grid>
            <Grid item xs={3.5} textAlign={'left'} color={battleStencil.opponentNameColor}>
              {battleStencil.opponentName}
            </Grid>
          </Grid>
          <Box paddingTop={4} paddingBottom={2} fontSize={32}>
            {deviceStatus.game_name}
          </Box>
        </Box>
      </Box>
      <Box textAlign='center' paddingTop={5} paddingBottom={5}>
        <Button variant="contained" color={battleStencil.leaveButtonColor} onClick={handleClose} startIcon={<CloseIcon />}>
          Leave Battle
        </Button>
      </Box>
      <Box textAlign='center' backgroundColor='#222' color='#666' paddingTop={0} paddingBottom={0} fontSize={13}>
        current server: {serverName} - latency: {Math.round( deviceStatus.latency / 100, 2) / 10}ms
      </Box>
    </Dialog>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const fbtTheme = createTheme({

  typography: {
    body1: {
    },
    h6: {
      textAlign: "left"
    },   
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: "repeating-linear-gradient(transparent, transparent 2px, #350260 10px, transparent 2px), #350268",
          backgroundSize: "auto 100%",
          backgroundPosition: "center center",
          opacity: 1,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
         root: {
           '&:hover': {
            backgroundColor: '#D5A2F0',
          },
           '&.Mui-selected': {
            backgroundColor: '#C2C2FF',
          },
         },
       },
     },
  },

});

export default function Dashboard( { page, setPage, device, deviceStatus, setDeviceStatus, recordDevice } ) {

  const [servers, setServers] = useState([]);
  const [battles, setBattles] = useState([]);
  const [games, setGames] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [battleBattlePopup, setBattlePopup] = React.useState(false);

  const handleGotoPage = (page, event) => {
    event.preventDefault();
    setPage(page);
  }

  const handleLogout = (event, fetchBattlesInterval, fetchDeviceStatusInterval) => {
    event.preventDefault();
    clearInterval(fetchDeviceStatusInterval);
    clearInterval(fetchBattlesInterval);
    recordDevice( '' );
    setDeviceStatus( [] );
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const closeBattlePopup =() => {
    setBattlePopup(false);
  };
  const fetchServers = () => {
    //console.log('fetch games');
    if( device != '' ) {
      fetch(`https://api.floppybattle.net/servers/all`)
        .then((response) => response.json())
        .then((actualData) => {
          //console.log(actualData);
          setServers(actualData.servers);
          //console.log(battles);
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
  };
  const fetchBattles = () => {
    //console.log('fetch battles');
    if( device != '' ) {
      fetch(`https://api.floppybattle.net/battles/all`)
        .then((response) => response.json())
        .then((actualData) => {
          //console.log(actualData);
          setBattles(actualData.battles);
        })
        .catch((err) => {
          console.log(err.message);
        });
     }
  };
  const fetchGames = () => {
    //console.log('fetch games');
    if( device != '' ) {
      fetch(`https://api.floppybattle.net/games/all`)
        .then((response) => response.json())
        .then((actualData) => {
          //console.log(actualData);
          setGames(actualData.games);
          //console.log(battles);
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
  };
  function fetchDeviceStatus(event) {
    //event.preventDefault();
    //console.log('fetch device_status');
    if( device != '' ) {
      fetch(`https://api.floppybattle.net/` + device + `/status`)
        .then((response) => response.json())
        .then((actualData) => {
          //console.log(actualData);
          setDeviceStatus(actualData.device_status)
        })
        .catch((err) => {
          console.log(err.message);
        });
     }
  }
  function quitBattle(event) {
    //event.preventDefault();
    //console.log('close Battle');
    if( device != '' ) {
      fetch(`https://api.floppybattle.net/` + device + `/battle/quit`)
        .then((response) => response.json())
        .then((actualData) => {
          //console.log(actualData);
        })
        .catch((err) => {
          console.log(err.message);
        });
     }
  }

  function getServerInfo(server_id) {
    var serverInfo = servers.find(x => x.server_id === server_id);
    //console.log(serverInfo);
    if( serverInfo !== undefined ) {
      return serverInfo;
    } else {
      return {'server_id':0,'name':'none'};
    }

  }

  function changeServer(server_id, event) {
    event.preventDefault();
    //console.log('change Server with server #' + server_id);
    fetch(`https://api.floppybattle.net/` + device + `/server/change/` + server_id)
      .then((response) => response.json())
      .then(() => {
        //deviceStatus.connected = false;
        //deviceStatus.authentified = false;
        //setDeviceStatus( deviceStatus );
        //event.Button.color = 'inherit';
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    setTimeout(()=>{fetchServers();}, 200);
    setTimeout(()=>{fetchGames();}, 1200);
    const fetchDeviceStatusInterval = setInterval(() => {
      fetchDeviceStatus();
    }, 2000);
    const fetchBattlesInterval = setInterval(() => {
      fetchBattles();
    }, 5500);
    const fetchServersInterval = setInterval(() => {
      fetchServers();
    }, 10000);
    return () => {
      clearInterval(fetchDeviceStatusInterval);
      clearInterval(fetchBattlesInterval);
      clearInterval(fetchServersInterval);
    }
  }, []);

  const MainContent = (

    <React.Fragment>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

        <Grid container spacing={3}>

          <Grid item xs={12} md={7} lg={8}>

            {/* Current Battles */}
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 700,
              }}
            >
              <Battles setBattlePopup={setBattlePopup} battles={battles} device={device} deviceStatus={deviceStatus} getServerInfo={getServerInfo} changeServer={changeServer} />
            </Paper>

          </Grid>

          {/* Games availables */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 700,
              }}
            >
              <Games setBattlePopup={setBattlePopup} games={games} device={device} deviceStatus={deviceStatus} />
            </Paper>
          </Grid>

        </Grid>
        
        <Footer sx={{ pt: 4 }} />

      </Container>

    </React.Fragment>

  );

  const ServersContent = (

    <React.Fragment>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

        <Grid container spacing={3}>

          <Grid item xs={12} md={7} lg={12}>

            {/* Current Servers */}
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 700,
              }}
            >
              <Servers servers={servers} device={device} deviceStatus={deviceStatus} setDeviceStatus={setDeviceStatus} changeServer={changeServer} />
            </Paper>

          </Grid>

        </Grid>
        
        <Footer sx={{ pt: 4 }} />

      </Container>

    </React.Fragment>

  );

  const AccountContent = (

    <React.Fragment>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

        <Grid container spacing={3}>

          <Grid item xs={12} md={7} lg={12}>

            {/* Account */}
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 700,
              }}
            >
              <Account device={device} deviceStatus={deviceStatus} getServerInfo={getServerInfo} />
            </Paper>

          </Grid>

        </Grid>
        
        <Footer sx={{ pt: 4 }} />

      </Container>

    </React.Fragment>

  );

  var PageContent;
  if( page === "account" ) {
    PageContent = AccountContent;
  } else if( page === "servers" ) {
    PageContent = ServersContent;
  } else {
    PageContent = MainContent;
  }

  return (
    <ThemeProvider theme={fbtTheme}>
      <DisconnectedWindow
        selectedValue={preventDefault}
        open={(page!=="account") && deviceStatus.connected == 0}
        page={page}
        setPage={setPage}
      />
      <BattleInProgressWindow
        selectedValue={preventDefault}
        open={deviceStatus.asked_battle_id > 0}
        onClose={closeBattlePopup}
        quitBattle={quitBattle}
        deviceStatus={deviceStatus}
        battles={battles}
        getServerInfo={getServerInfo}
      />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              <Grid container width={270} paddingTop={1} spacing={2} alignItems={'center'}>
                <Grid item>
                  <img src="logo512.png" width={45} height={45}  />
                </Grid>
                <Grid item>
                  FloppyBattle.NET
                </Grid>
              </Grid>
            </Typography>
            <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              <Grid container width={'*'} flexDirection={'row-reverse'} paddingRight={2} spacing={2} alignItems={'center'}>
                <Grid item>
                  {deviceStatus.name}
                </Grid>
              </Grid>
            </Typography>
            <IconButton color="inherit" disabled>
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" onClick={handleLogout}>
              <Badge badgeContent={0} color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" onClick={(event) => handleGotoPage('home', event)}>
              <Badge badgeContent={0} color="secondary">
                <HomeIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <MainListItems page={page} setPage={setPage} />
              <Divider sx={{ my: 1 }} />
              {secondaryListItems}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
         <Toolbar />

        {PageContent}

        </Box>
      </Box>
    </ThemeProvider>
  );
}

/*
export default function Dashboard() {
  return <DashboardContent />;
}
*/
