import * as React from 'react';
//import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import DnsIcon from '@mui/icons-material/Dns';

import Title from './Title';


//function preventDefault(event) {
//  event.preventDefault();
//}

export default function Servers( { servers, device, deviceStatus, setDeviceStatus, changeServer } ) {

  function makeServerButton( server_id ) {

    if( ! deviceStatus.connected || server_id !== deviceStatus.server_id ) {

      return (
        <React.Fragment >
          <Button
            variant="contained"
            color={'primary'}
            onClick={(event) => changeServer(server_id, event)}
            endIcon={<DnsIcon />}>
            Join 
          </Button>
        </React.Fragment>
      );

    } else {

      return (
        <React.Fragment >
          <Button
            variant="contained"
            color={'inherit'}
            style={{pointerEvents: "none", opacity: "0.4"}}
            >
            Connected
          </Button>
        </React.Fragment>
      );

    }

  }

  var lineColor;

  return (
    <React.Fragment>
      <div style={deviceStatus.authentified !== true ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Title>Online game servers</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Players</TableCell>
              <TableCell>Latency (ms)</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {servers.sort((a, b) => (a.server_id > b.server_id ? 1 : -1)).map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={{color:'grey'}}>{item.name}</TableCell>
              <TableCell>{item.location} /{item.country}</TableCell>
              <TableCell>{item.devices_count}</TableCell>
              <TableCell>{item.server_id == deviceStatus.server_id ? Math.round( deviceStatus.latency / 100, 2) / 10 : '-' }</TableCell>
              <TableCell>{makeServerButton(item.server_id)}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

//<Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
//  See more battles
//</Link>