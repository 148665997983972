export const arcadeCompany = {
    prefix: "fab",
    iconName: "arcadecompany",
    icon: [
      50,
      50,
      [],
      "e002",
      "M 25 4 A 6 6 0 0 0 25 16 A 6 6 0 0 0 25 4 z M 23 17.867188 C 22.415 17.987188 21.837391 18.155859 21.275391 18.380859 L 3.2070312 25.630859 C 1.5940312 26.277859 1.5179844 28.532109 3.0839844 29.287109 L 18.484375 36.714844 C 22.600375 38.699844 27.397672 38.699844 31.513672 36.714844 L 46.914062 29.287109 C 48.481063 28.532109 48.405969 26.277859 46.792969 25.630859 L 28.724609 18.380859 C 28.163609 18.155859 27.585 17.988141 27 17.869141 L 27 27.914062 C 27 29.019063 26.105 29.914062 25 29.914062 C 23.895 29.914062 23 29.019063 23 27.914062 L 23 17.867188 z M 11.5 26 C 13.433 26 15 26.895 15 28 C 15 29.104 13.433 30 11.5 30 C 9.567 30 8 29.104 8 28 C 8 26.895 9.567 26 11.5 26 z M 2.9199219 32.556641 C 2.427875 32.592266 2 33.001031 2 33.550781 L 2 36.257812 C 2 36.642812 2.2203594 36.992203 2.5683594 37.158203 L 17.496094 44.298828 C 22.241094 46.567828 27.758906 46.567828 32.503906 44.298828 L 47.431641 37.158203 C 47.779641 36.991203 48 36.640859 48 36.255859 L 48 33.556641 C 48 32.821641 47.235219 32.337203 46.574219 32.658203 C 42.720219 34.522203 32.382813 39.515625 32.382812 39.515625 C 30.094812 40.619625 27.541 41.203125 25 41.203125 C 22.459 41.203125 19.904234 40.619625 17.615234 39.515625 C 17.615234 39.515625 7.2689219 34.517297 3.4199219 32.654297 C 3.2551719 32.574547 3.0839375 32.544766 2.9199219 32.556641 z"
    ]
  };
  