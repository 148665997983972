import * as React from 'react';
//import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Title from './Title';

//function preventDefault(event) {
//  event.preventDefault();
//}


export default function Games( { setBattlePopup, games, device, deviceStatus } ) {

  function createBattle(game_id, event) {
    event.preventDefault();
    //console.log('create Battle with game #' + game_id);
    fetch(`https://api.floppybattle.net/` + device + `/battle/create/game/` + game_id)
      .then((response) => response.json())
      .then((actualData) => {
        //console.log(actualData);
        setBattlePopup(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function createMakeName(release) {
    switch(release) {
      case 'a': return 'In work';
      case 'ß': return 'Beta';
      default: return 'launch';
    }
  }

  return (

    <React.Fragment>
      <div style={deviceStatus.authentified !== true ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Title>Begin your own battle</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Game</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {games.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.game_name}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  fullWidth
                  color={item.game_release !== 'rc' ? 'inherit' : 'primary'}
                  style={item.game_release === 'a' ? {pointerEvents: "none", opacity: "0.4"} : {}}
                  onClick={(event) => createBattle(item.game_id, event)}
                  endIcon={item.game_release === 'a' ? <SettingsSuggestIcon /> : <RocketLaunchIcon />}
                  >
                  {createMakeName(item.game_release)}
                </Button>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

//<Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
//  See more battles
//</Link>